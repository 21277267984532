import React, { useState, useEffect } from "react";
import managerService from "../../../Services/managerService";
import ViewCategories from "./ViewCategories";
import AdminService from "../../../Services/adminService";

const ManageCategory = () => {
    const [categoryName, setCategoryName] = useState("");
    const [status, setStatus] = useState(true); 
    const [loading,setLoading]=useState(false); 

  const handleSubmit = (e) => {
    e.preventDefault();
    const newCategory={
        categoryName:categoryName,
    }
    setLoading(true);
    AdminService.addCategory(newCategory).then((res) => {
      console.log(res);
      if (res.error) {
        console.log('error in adding category', res)
        alert(res.error);
        setLoading(false);
      } else {
        alert("Category Added Successfully");
        setCategoryName("");
        setStatus(true);
        setLoading(false);
      }
    });
  };

  return (
    <div className="text-center flex justify-center min-h-screen lg:flex-row flex-col">
      <div className="m-10 p-5 sm:p-10 lg:w-1/2">
        <h1 className="text-2xl text-blue-500 mb-3">Add Category</h1>
        <p className="text-gray-500 dark:text-gray-400 mb-10">
          Add a new category to the system
        </p>
        <div className="flex flex-col justify-center gap-10">
          <div className="flex flex-col w-full">
            <h1 className="text-left text-black text-md">Category Name</h1>
            <input
              type="text"
              placeholder="Category Name"
              value={categoryName}
              className="p-2 border border-gray-300 rounded-xl"
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </div>
          <div className="flex flex-col w-full">
            <h1 className="text-left text-black text-md">Status</h1>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={status}
                onChange={() => setStatus(!status)}
                className="h-5 w-5"
              />
              <span className="text-gray-500">Active</span>
            </label>
          </div>
          <button
            className="bg-blue-500 text-white p-2 rounded-xl"
            onClick={handleSubmit}
          >
            Add Category
          </button>
        </div>
      </div>
      <div className="w-full lg:w-1/2">
       {
        loading ? (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        ):(
            <ViewCategories className="w-full" />
        )
       }
      </div>
    </div>
  );
};

export default ManageCategory;
