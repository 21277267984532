import React, { useEffect } from 'react'
import useStore from '../Store/store';

const AuthRoute = ({ children }) => {
    const { userRole, setUserRole } = useStore();

    if (userRole && userRole !== 'null') {
        { userRole === 'admin' && (window.location.href = '/admin/dashboard') }
        { userRole === 'manager' && (window.location.href = '/manager/dashboard') }
        { userRole === 'cashier' && (window.location.href = '/cashier/dashboard') }
        { userRole === 'kitchen' && (window.location.href = '/kitchen/orders') }
    } else {
        return (
            <>
                {children}
            </>
        )
    }

}

export default AuthRoute
