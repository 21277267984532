import React, { useState, useEffect } from "react";
import managerService from "../../../Services/managerService";
import commonService from "../../../Services/common";
import AdminService from "../../../Services/adminService";

const ViewCategories = () => {
    const [categories, setCategories] = useState([]);
    const [submitPressed, setSubmitPressed] = useState(false);

    const fetchCategories = async () => {
        setSubmitPressed(!submitPressed);

        AdminService.getCategories().then((data) => {
            if (data.error) {
                console.log(data.error);
            } else {
                console.log(data.data);
                setCategories(data.data);
            }
        });
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <div class="relative p-10 mt-10 min-h-screen">
            <h1 class="text-2xl text-blue-500 mb-2">View Categories</h1>
            <p class="text-gray-500 dark:text-gray-400 mb-10">
                View all Categories
            </p>

            <div className="overflow-x-auto">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Category ID
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Category Name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-gray-800 divide-y dark:divide-gray-700">
                        {categories.map((category) => (
                            <tr key={category._id}>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div class="ml-4">
                                            <div class="text-sm font-medium text-gray-200">
                                                {commonService.handleID(category._id)}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="text-sm text-gray-300">{category.category_name}</div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="text-sm text-gray-300">{
                                        category.status ? "Active" : "Inactive"
                                    }
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ViewCategories;
