import React from "react";
import { HashRouter, Routes, Route, NavLink } from "react-router-dom";

import Home from "./Home/page";
import Register from "./Admin/Register/Register";
import Contact from "./Home/contact";
import AdminLogin from "./Admin/Login/page";
import UserLogin from "./UserLogin/page";
import DashboardLayout from "./Admin/Dashboard/DashboardLayout";
import CashierDashboardLayout from "./Cashier/Dashboard/DashboardLayout";
import ManagerDashboardLayout from "./Manager/DashboardLayout";
import Orders from "./Kitchen/Orders/page";
import CashierLogin from "./Cashier/Login/page";
import ManagerLogin from "./Manager/Login/page";
import KitchenLogin from "./Kitchen/Login/page";
import Login from "./Login/login";
import AuthRoute from "./Components/AuthRoute";

const Layout = () => {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/register" element={<AuthRoute><Register/></AuthRoute>} />
      <Route path="/login" element={<AuthRoute><Login /></AuthRoute>} />
      <Route path="/user/login" element={<AuthRoute><UserLogin /></AuthRoute>} />
      <Route path="/admin/login" element={<AuthRoute><AdminLogin /></AuthRoute>} />
      <Route path="/cashier/login" element={<AuthRoute><CashierLogin /></AuthRoute>} />
      <Route path="/manager/login" element={<AuthRoute><ManagerLogin /></AuthRoute>} />
      <Route path="/kitchen/login" element={<AuthRoute><KitchenLogin /></AuthRoute>} />
      
      <Route path="/admin/dashboard" element={<DashboardLayout />} />
      <Route path="/manager/dashboard" element={<ManagerDashboardLayout />} />

      <Route path="/cashier/dashboard" element={<CashierDashboardLayout />} />
      
      <Route path="/kitchen/orders" element={<Orders />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
};

export default Layout;
