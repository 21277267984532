import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import AuthService from "../../Services/authService";


const Register = () => {
  const [shopName, setShopName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Simple validation to check passwords match
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    AuthService.registerShop({ shopName, email, password }).then((res) => {
      console.log(res);
      if (res === "error") {
        alert("Error during registration.");
      } else {
        alert("Registration Successful!");
        clearForm();
        window.location.href = "/login";  
      }
    });
  };

  const clearForm = () => {
    setShopName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      
      {/* Main Content */}
      <div className="flex flex-col items-center justify-center px-4 sm:px-6 md:px-8 py-8 mt-24">
        {/* Register Card */}
        <div className="w-full max-w-md bg-white p-6 sm:p-8 rounded-lg shadow-lg">
          <h1 className="text-xl sm:text-2xl text-blue-500 mb-6 font-semibold text-center">
            Register Account
          </h1>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-2">
              <label htmlFor="shopName" className="block text-sm font-medium text-gray-700">
                {"Shop Name(username)"}
              </label>
              <input
                id="shopName"
                type="text"
                value={shopName}
                onChange={(e) => setShopName(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your shop name"
                required
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your password"
                required
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Confirm your password"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-600 text-white py-3 px-4 rounded-lg transition duration-200"
            >
              Register
            </button>

            <div className="flex justify-between text-sm">
              <a href="#" className="text-blue-500 hover:text-blue-600">
                Forgot Password?
              </a>
              <a href="/login" className="text-blue-500 hover:text-blue-600">
                Already have an account? Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
